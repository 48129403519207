import { FunctionComponent, SVGProps } from 'react';

export const CaretDownIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="caret-down"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            {...props}
        >
            <path d="M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z"></path>
        </svg>
    );
};

export default CaretDownIcon;
